import React from 'react';

import { Box, IconButton, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SettingsIcon from '@mui/icons-material/Settings';

import AccountMenu from '../Desktop/AccountMenu';
import useAccountTimesheetManagement from '../../hooks/useAccountTimesheetManagement';
import useTranslation from '../../hooks/useTranslation';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    margin: theme.spacing(0, 2, 0, 0),
  },
  banner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mediaHeader: {
    height: '8.15rem',
    maxHeight: '15vh',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: 'solid',
    borderBottomColor: theme.palette.custom.paper,
  },
  settingsIcon: {
    height: theme.spacing(5),
    width: theme.spacing(5),
  },
  accountMenu: {
    padding: theme.spacing(0, 0, 0, 3),
  },
}));

export interface DesktopHeaderProps {
  onLogOutClick: () => void;
  showSettings: () => void;
}

const DesktopHeader: React.FC<DesktopHeaderProps> = ({
  onLogOutClick,
  showSettings,
}) => {
  const classes = useStyles();

  const { currentAccountData, userAccounts, onSelectUserAccount } =
    useAccountTimesheetManagement();

  const t = useTranslation();

  return (
    <Box>
      <Paper className={classes.banner}>
        {t('The OnTime App will be working until April 30, 2025')}
      </Paper>
      <Box className={classes.mediaHeader}>
        <Box className={classes.accountMenu}>
          <AccountMenu
            userAccounts={userAccounts}
            onSelectAccount={onSelectUserAccount}
            selectedCompany={currentAccountData?.companyName}
            onLogout={onLogOutClick}
          />
        </Box>
        <IconButton
          aria-label="settings"
          onClick={showSettings}
          className={classes.iconButton}
          size="large"
        >
          <SettingsIcon className={classes.settingsIcon} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default DesktopHeader;
