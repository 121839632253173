import React from 'react';
import { Box, Paper, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import CalendarIcon from './../../icons/CalendarIcon';
import SettingsIcon from './../../icons/SettingsIcon';
import OnTimeHomeIcon from './../../icons/OnTimeHomeIcon';
import LeaveRequestIcon from './../../icons/LeaveRequestIcon';
import { ReactComponent as ExpenseClaimIcon } from './../../images/expense-icon.svg';
import useTranslation from '../../hooks/useTranslation';
import GuardedFooterNav from './GuardedFooterNav';

const useStyles = makeStyles((theme) => ({
  footerNav: {
    borderTop: `1px solid ${theme.palette.custom.lightGray}`,
    padding: theme.spacing(2, 0),
    '@media (max-height: 420px)': {
      padding: theme.spacing(1, 2),
    },
  },
  mobileBanner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '2rem',
  },
  link: {
    color: theme.palette.custom.gray,
    textDecoration: 'none',
    '@media (max-height: 420px)': {
      '& > div': {
        float: 'left',
        marginLeft: theme.spacing(1),
        lineHeight: '1.5em',
      },
    },
  },
  leaveIcon: {
    width: '100%',
    '& .b-leave-request': {
      fill: theme.palette.custom.gray,
    },
  },
  expenseIcon: {
    '& .st0': {
      stroke: theme.palette.custom.gray,
    },
    '& .st1': {
      stroke: theme.palette.custom.gray,
    },
    '& .st2': {
      stroke: theme.palette.custom.gray,
    },
    '& .st3': {
      stroke: theme.palette.custom.gray,
    },
    '& .st4': {
      stroke: theme.palette.custom.gray,
    },
  },
  homeIcon: {
    // fontSize: theme.spacing( 2 ),
    '& .b': {
      fill: theme.palette.custom.gray,
    },
    '& .a': {
      stroke: theme.palette.custom.gray,
    },
  },
  activeLink: {
    color: theme.palette.custom.turquoise,
    '& .b': {
      fill: theme.palette.custom.turquoise,
    },
    '& .a': {
      stroke: theme.palette.custom.turquoise,
    },
    '& .b-leave-request': {
      fill: theme.palette.custom.turquoise,
    },
    '& .st0': {
      stroke: theme.palette.custom.turquoise,
    },
    '& .st1': {
      stroke: theme.palette.custom.turquoise,
    },
    '& .st2': {
      stroke: theme.palette.custom.turquoise,
    },
    '& .st3': {
      stroke: theme.palette.custom.turquoise,
    },
    '& .st4': {
      stroke: theme.palette.custom.turquoise,
    },
  },
}));

const FooterMenu: React.FC = () => {
  const classes = useStyles();

  const t = useTranslation();

  return (
    <>
      <Paper className={classes.mobileBanner}>
        {t('The OnTime App will be working until April 30, 2025')}
      </Paper>
      <nav className={classes.footerNav}>
        <Box display="flex">
          <Box flex="1" textAlign="center">
            <GuardedFooterNav
              className={(navData) => {
                if (navData.isActive) {
                  return classes.activeLink;
                }
                return classes.link;
              }}
              to="/"
              // exact={ true }
            >
              <Box>
                <OnTimeHomeIcon
                  width={24}
                  height={24}
                  className={classes.homeIcon}
                />
              </Box>
              <Box>
                <Typography variant="caption">{t('home')}</Typography>
              </Box>
            </GuardedFooterNav>
          </Box>
          <Box flex="1" textAlign="center">
            <GuardedFooterNav
              className={(navData) => {
                if (navData.isActive) {
                  return classes.activeLink;
                }
                return classes.link;
              }}
              to="/calendar"
            >
              <Box>
                <CalendarIcon width={24} height={24} />
              </Box>
              <Box>
                <Typography variant="caption">{t('calendar')}</Typography>
              </Box>
            </GuardedFooterNav>
          </Box>
          <Box flex="1" textAlign="center">
            <GuardedFooterNav
              className={(navData) => {
                if (navData.isActive) {
                  return classes.activeLink;
                }
                return classes.link;
              }}
              to="/leave"
            >
              <Box>
                <LeaveRequestIcon
                  width={24}
                  height={24}
                  className={classes.leaveIcon}
                />
              </Box>
              <Box>
                <Typography variant="caption">{t('leave')}</Typography>
              </Box>
            </GuardedFooterNav>
          </Box>
          <Box flex="1" textAlign="center">
            <GuardedFooterNav
              to="/expense-claims"
              className={(navData) => {
                if (navData.isActive) {
                  return classes.activeLink;
                }
                return classes.link;
              }}
            >
              <Box>
                <ExpenseClaimIcon
                  width={24}
                  height={24}
                  className={classes.expenseIcon}
                />
              </Box>
              <Box>
                <Typography variant="caption">{t('expenses')}</Typography>
              </Box>
            </GuardedFooterNav>
          </Box>
          <Box flex="1" textAlign="center">
            <GuardedFooterNav
              to="/settings"
              className={(navData) => {
                if (navData.isActive) {
                  return classes.activeLink;
                }
                return classes.link;
              }}
            >
              <Box>
                <SettingsIcon width={24} height={24} />
              </Box>
              <Box>
                <Typography variant="caption">{t('settings')}</Typography>
              </Box>
            </GuardedFooterNav>
          </Box>
        </Box>
      </nav>
    </>
  );
};

export default FooterMenu;
